<template >
  <div class="justify-content-between align-items-center content-sidebar px-1 py-1">
      <div >
        <h2 class="mb-0"><strong> {{ orders.order_no }} </strong> </h2>
    </div>
    <hr>
        <b-form>
            <b-row>

                <b-col
                    cols="12"
                    class="mb-2"
                >
                    <h5 class="mb-0">
                        {{ $t('orderView.title') }}
                    </h5>
                </b-col>

                <b-col md="6">
                    <b-form-group
                    :label="$t('orderView.PartNumber')"
                    label-for="part_no"
                    >
                    <b-form-input
                    id="part_no"
                    :value="orders.part_no"
                    readonly
                    />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group
                    :label="$t('orderView.Product')"
                    label-for="product"
                    >
                    <b-form-input
                    id="product"
                    :value="orders.product"
                    readonly
                    />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group
                    :label="$t('orderView.Quantity')"
                    label-for="quantity"
                    >
                    <b-form-input
                    id="quantity"
                    :value="orders.planned_quantity"
                    readonly
                    />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group
                    :label="$t('orderView.Priority')"
                    label-for="priority"
                    >
                    <b-form-input
                    id="priority"
                    :value="orders.priority"
                    readonly
                    />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group
                    :label="$t('orderView.EarliestStartDate')"
                    label-for="earliest_start_date"
                    >
                    <b-form-input
                    id="earliest_start_date"
                    :value="formatDateTime(orders.earliest_start_date)"
                    readonly
                    />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group
                    :label="$t('orderView.DueDate')"
                    label-for="due_date"
                    >
                    <b-form-input
                    id="due_date"
                    :value="formatDateTime(orders.due_date)"
                    readonly
                    />
                    </b-form-group>
                </b-col>

                <b-col
                    cols="12"
                    class="mb-2"
                >
                    <h5 class="mb-0">
                    <hr>
                    </h5>
                </b-col>

                <b-col
                    cols="12"
                    class="mb-2"
                >
                    <h5 class="mb-0">
                        {{ $t('orderView.OperationInformation') }}
                    </h5>
                </b-col>
                
                <b-col md="4">
                    <b-form-group
                    :label="$t('orderView.OperationNumber')"
                    label-for="op_no"
                    >
                    <b-form-input
                    id="op_no"
                    :value="orders.op_no"
                    readonly
                    />
                    </b-form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group
                    :label="$t('orderView.OperationName')"
                    label-for="operation_name"
                    >
                    <b-form-input
                    id="operation_name"
                    :value="orders.operation_name"
                    readonly
                    />
                    </b-form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group
                    :label="$t('orderView.Workcenter')"
                    label-for="workcenter"
                    >
                    <b-form-input
                    id="workcenter"
                    :value="orders.workcenter"
                    readonly
                    />
                    </b-form-group>
                </b-col>

                <b-col
                    cols="12"
                    class="mb-2"
                >
                    <h5 class="mb-0">
                    <hr>
                    </h5>
                </b-col>

                <b-col
                    cols="12"
                    class="mb-2"
                >
                    <h5 class="mb-0">
                         {{ $t('orderView.Attributes') }}
                    </h5>
                </b-col>

                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'Table Attribute 1') ?  (this.attributesFields.find(el => el.native_name == 'Table Attribute 1')).name :'Table Attribute 1'"
                    label-for="att_1"
                    >
                    <b-form-input
                    id="att_1"
                    :value="orders.att_1"
                    readonly
                    />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'String Attribute 1') ?  (this.attributesFields.find(el => el.native_name == 'String Attribute 1')).name :'Text Attribute 1'"
                    label-for="string_attribute_1"
                    >
                    <b-form-input
                    id="string_attribute_1"
                    :value="orders.string_attribute_1"
                    readonly
                    />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'Numerical Attribute 1') ?  (this.attributesFields.find(el => el.native_name == 'Numerical Attribute 1')).name :'Numerical Attribute 1'"
                    label-for="numeric_attribute_1"
                    >
                    <b-form-input
                    id="numeric_attribute_1"
                    :value="orders.numeric_attribute_1"
                    readonly
                    />
                    </b-form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'Table Attribute 2') ?  (this.attributesFields.find(el => el.native_name == 'Table Attribute 2')).name :'Table Attribute 2'"
                    label-for="att_2"
                    >
                    <b-form-input
                    id="att_2"
                    :value="orders.att_2"
                    readonly
                    />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'String Attribute 2') ?  (this.attributesFields.find(el => el.native_name == 'String Attribute 2')).name :'Text Attribute 2'"
                    label-for="string_attribute_2"
                    >
                    <b-form-input
                    id="string_attribute_2"
                    :value="orders.string_attribute_2"
                    readonly
                    />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'Numerical Attribute 2') ?  (this.attributesFields.find(el => el.native_name == 'Numerical Attribute 2')).name :'Numerical Attribute 2'"
                    label-for="numeric_attribute_2"
                    >
                    <b-form-input
                    id="numeric_attribute_2"
                    :value="orders.numeric_attribute_2"
                    readonly
                    />
                    </b-form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'Table Attribute 3') ?  (this.attributesFields.find(el => el.native_name == 'Table Attribute 3')).name :'Table Attribute 3'"
                    label-for="att_3"
                    >
                    <b-form-input
                    id="att_3"
                    :value="orders.att_3"
                    readonly
                    />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'String Attribute 3') ?  (this.attributesFields.find(el => el.native_name == 'String Attribute 3')).name :'Text Attribute 3'"
                    label-for="string_attribute_3"
                    >
                    <b-form-input
                    id="string_attribute_3"
                    :value="orders.string_attribute_3"
                    readonly
                    />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'Numerical Attribute 3') ?  (this.attributesFields.find(el => el.native_name == 'Numerical Attribute 3')).name :'Numerical Attribute 3'"
                    label-for="numeric_attribute_3"
                    >
                    <b-form-input
                    id="numeric_attribute_3"
                    :value="orders.numeric_attribute_3"
                    readonly
                    />
                    </b-form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'Table Attribute 4') ?  (this.attributesFields.find(el => el.native_name == 'Table Attribute 4')).name :'Table Attribute 4'"
                    label-for="att_4"
                    >
                    <b-form-input
                    id="att_4"
                    :value="orders.att_4"
                    readonly
                    />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'String Attribute 4') ?  (this.attributesFields.find(el => el.native_name == 'String Attribute 4')).name :'Text Attribute 4'"
                    label-for="string_attribute_4"
                    >
                    <b-form-input
                    id="string_attribute_4"
                    :value="orders.string_attribute_4"
                    readonly
                    />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'Numerical Attribute 4') ?  (this.attributesFields.find(el => el.native_name == 'Numerical Attribute 4')).name :'Numerical Attribute 4'"
                    label-for="numeric_attribute_4"
                    >
                    <b-form-input
                    id="numeric_attribute_4"
                    :value="orders.numeric_attribute_4"
                    readonly
                    />
                    </b-form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'Table Attribute 5') ?  (this.attributesFields.find(el => el.native_name == 'Table Attribute 5')).name :'Table Attribute 5'"
                    label-for="att_5"
                    >
                    <b-form-input
                    id="att_5"
                    :value="orders.att_5"
                    readonly
                    />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'String Attribute 5') ?  (this.attributesFields.find(el => el.native_name == 'String Attribute 5')).name :'Text Attribute 5'"
                    label-for="string_attribute_5"
                    >
                    <b-form-input
                    id="string_attribute_5"
                    :value="orders.string_attribute_5"
                    readonly
                    />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group
                    :label="this.attributesFields.find(el => el.native_name == 'Numerical Attribute 5') ?  (this.attributesFields.find(el => el.native_name == 'Numerical Attribute 5')).name :'Numerical Attribute 5'"
                    label-for="numeric_attribute_5"
                    >
                    <b-form-input
                    id="numeric_attribute_5"
                    :value="orders.numeric_attribute_5"
                    readonly
                    />
                    </b-form-group>
                </b-col>

              <b-col
                    cols="12"
                    class="mb-2"
                >
                    <h5 class="mb-0">
                    <hr>
                    </h5>
                </b-col>

                <b-col
                    cols="12"
                    class="mb-2"
                >
                    <h5 class="mb-0">
                         {{ $t('orderView.Notes') }}
                    </h5>
                </b-col>

                <b-col md="12">
                    <b-form-group
                    label="Note"
                    label-for="note"
                    >
                    <b-form-textarea
                    id="note"
                    :value="orders.note"
                    readonly
                    />
                    </b-form-group>
                </b-col>

            </b-row>
        </b-form>

  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BAvatar, BListGroup, BListGroupItem, BCardText, BForm, BFormGroup, BFormInput, BFormSelect, BButton, BSpinner, BRow, BCol, BFormTextarea
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    flatPickr,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props:{
    orderId: {
      type: String,
    },
  },
  watch:{
    orderId: function(newVal, oldVal){
      this.getOrderById(newVal)
    }
  },
  data() {
    return {
      currentSite: JSON.parse(localStorage.getItem('currentSite')),
      orders: [],
      attributesFields: [],
    }
  },
  created(){
    this.getAttributesFields()
  },
  methods: {
    getOrderById(id){
      this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/order/${id}?site_guid=${this.currentSite.guid}`)
        .then(res => { this.orders = res.data.response;})
    },
    getAttributesFields(){
      this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/attribute-field?site_guid=${this.currentSite.guid}`)
        .then(res => {this.attributesFields = res.data.response})
    },
    formatDateTime(dateTime){
      if (dateTime)
        return dateTime.substring(0,16);
      return
    }
  }
}
</script>